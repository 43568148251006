import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Table, Button, Label, Message } from 'semantic-ui-react';
import './voting.css';
import { getVotes, vote, clearVotes } from '../../actions';

const Voting = () => {
  const votes = useSelector((store) => store.votes);
  const dispatch = useDispatch();
  let location = useLocation();
  const [stateClearVotes, setStateClearVotes] = React.useState(0);
  const handleVoteClick = (value) => {
    if (location) {
      dispatch(vote(location.pathname, value));
      setStateClearVotes(0);
    }
  };
  const handleClearVotes = () => {
    if (location && stateClearVotes === 1) {
      dispatch(clearVotes(location.pathname));
    }
    let counter = stateClearVotes < 2 ? stateClearVotes + 1 : 2;
    setStateClearVotes(counter);
  };
  React.useEffect(() => {
    if (location) {
      dispatch(getVotes(location.pathname));
    }
  }, [dispatch, location]);
  return votes?.loaded && votes?.can_vote && !votes?.error ? (
    <div className="voting ui floating message">
      {votes?.already_voted ? (
        <p id="already_voted">You already voted.</p>
      ) : votes?.can_vote ? (
        <div id="notyetvoted">
          <Button.Group className="votes">
            <Button color="green" onClick={() => handleVoteClick(1)}>
              +1
            </Button>{' '}
            <Button color="yellow" onClick={() => handleVoteClick(0)}>
              0
            </Button>
            <Button color="red" onClick={() => handleVoteClick(-1)}>
              -1
            </Button>
          </Button.Group>
        </div>
      ) : null}
      {votes?.has_votes ? (
        <div id="current_rating">
          Average vote: <strong>{votes.average_vote}</strong>
        </div>
      ) : votes?.can_vote ? (
        <span id="no_ratings"> Nobody has voted yet. </span>
      ) : null}
      {votes?.has_votes && votes?.can_clear_votes ? (
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Voted By</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>{votes?.has_voted}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <div>
                {votes?.can_clear_votes ? (
                  <Button
                    id="delete_votings delete_votings2"
                    className="areyousure"
                    color="red"
                    onClick={handleClearVotes}
                  >
                    {['Delete all votes', 'Confirm Delete'][stateClearVotes]}
                  </Button>
                ) : null}
              </div>
            </Table.Row>
          </Table.Body>
        </Table>
      ) : null}
    </div>
  ) : null;
};
export default Voting;
