import moment from 'moment';
import { Container, Form, Grid, Segment } from 'semantic-ui-react';
import React, { useEffect, useState } from 'react';
import PDFViews from 'volto-yc-documents/components/PDFViews/PDFViews';
import YCImageView from 'volto-yc-documents/components/YCImageView/YCImageView';
import Helmet from '@plone/volto/helpers/Helmet/Helmet';
import DefaultLoader from 'volto-yc-documents/components/ContentTypesViews/HelperComponents/DefaultLoader';
import { useSelector, useDispatch } from 'react-redux';
import { getUser } from '@plone/volto/actions/users/users';
import jwtDecode from 'jwt-decode';

const CPLDocList = (props) => {
  const { content, token } = props;
  const id = jwtDecode(token).sub;
  const dispatch = useDispatch();
  const email = (id) => {
    let response = dispatch(getUser(id));
    let email = response?.email;
    return email;
  };
  const AttachmentSelector = ({ type, file }) => {
    return (
      <div>
        {content?.preview === 'True' ? (
          <div>
            <p>
              <strong> Attachment: </strong>
              {(type && type?.title) ||
                (type && type) ||
                (type && type?.toString()) ||
                (type && type?.token)}{' '}
              - <a href={file?.download}>{file?.filename}</a>
            </p>
            {file ? (
              <PDFViews
                file={file?.download}
                type={file?.['content-type']}
                filename={file?.filename}
              />
            ) : (
              <YCImageView file={file?.scales?.preview?.download} />
            )}{' '}
          </div>
        ) : (
          <p>
            <strong> Attachment: </strong>
            {(type && type?.title) ||
              (type && type) ||
              (type && type?.toString()) ||
              (type && type?.token)}{' '}
            - {file?.filename}
          </p>
        )}
      </div>
    );
  };

  const FieldSelector = ({ label, field }) => {
    if (label && field) {
      return (
        <span>
          <strong>{field && label}: </strong>
          {field && field}
          <br />
        </span>
      );
    }
    return <span>{field && field}</span>;
  };
  const description =
    content?.last_name +
    ', ' +
    content?.first_name +
    ' - ' +
    content?.employeeID;
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    let timer = setTimeout(() => setLoaded(true), 200);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  return (
    <Container>
      {!loaded ? (
        <DefaultLoader active={!loaded} />
      ) : (
        <Form>
          <Helmet title={description} />
          <h1 className="documentFirstHeading">{description}</h1>
          <br />
          <Grid stackable>
            <Grid.Row columns={2}>
              {/* Column 1 */}
              <Grid.Column>
                <Grid>
                  <Grid.Row>
                    {/* Column 1.1.1 */}
                    <Grid.Column>
                      {content?.street_address && (
                        <FieldSelector
                          label="Street Address"
                          field={content?.street_address}
                        />
                      )}
                      {content?.city && (
                        <FieldSelector
                          label="City"
                          field={content?.city}
                        />
                      )}
                      {content?.state && (
                        <FieldSelector
                          label="State"
                          field={content?.state}
                        />
                      )}
                      {content?.zip_code && (
                        <FieldSelector
                          label="Zip Code"
                          field={content?.zip_code}
                        />
                      )}
                      {id && (
                        <FieldSelector
                          label="Email"
                          field={email(id)}
                        />
                      )}
                      {content?.intended_major && (
                        <FieldSelector
                          label="Intended Major"
                          field={
                            content?.intended_major?.title ||
                            content?.intended_major?.token
                          }
                        />
                      )}
                      {content?.semester && (
                        <FieldSelector
                          label="Semester"
                          field={content?.semester}
                        />
                      )}
                      {content?.year && (
                        <FieldSelector
                          label="Year"
                          field={content?.year}
                        />
                      )}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
              {/* Column 2 */}
              <Grid.Column>
                {content?.rubric && (
                  <AttachmentSelector
                    type="Rubric"
                    file={content?.rubric}
                    filename={content?.rubric}
                  />
                )}
                {content?.memo && (
                  <FieldSelector label="Memo" field={content?.memo} />
                )}
                {content?.credits_awarded && (
                  <FieldSelector
                    label="Credits Awarded"
                    field={content?.credits_awarded}
                  />
                )}
                {content?.semester_credits_awarded && (
                  <FieldSelector
                    label="Semester Credits are Awarded"
                    field={
                      content?.semester_credits_awarded?.title ||
                      content?.semester_credits_awarded?.token
                    }
                  />
                )}
                {content?.year_credits_awarded && (
                  <FieldSelector
                    label="Year Credits are Awarded"
                    field={
                      content?.year_credits_awarded?.title ||
                      content?.year_credits_awarded?.token
                    }
                  />
                )}
                {content?.course_credits_awarded_for && (
                  <FieldSelector
                    label="Course Credits Awarded For"
                    field={
                      content?.course_credits_awarded_for?.title ||
                      content?.course_credits_awarded_for?.token
                    }
                  />
                )}
                {content?.confidentiality_code && (
                  <FieldSelector
                    label="Confidentiality Code for CPL Designees"
                    field={content?.confidentiality_code}
                  />
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <br />
          <span>
              {content?.are_you_currently_enrolled_at_york_college_ &&
                content?.not_enrolled && (
                  <FieldSelector
                    label="Last Semester of Attendance"
                    field={content?.not_enrolled}
                  />
                )}

            {content?.other_colleges && (
              <div>
                <FieldSelector
                  label="Other College Name"
                  field={content?.other_colleges_name}
                />
                <FieldSelector
                  label="Other College Dates Attended"
                  field={content?.other_college_dates_attended}
                />
                {content?.other_college_transcript && (
                  <AttachmentSelector
                    type="Other College Transcript"
                    file={content?.other_college_transcript}
                    filename={content?.other_college_transcript}
                  />
                )}
              </div>
            )}
            {content?.course && (
              <FieldSelector
                label="Course"
                field={
                  content?.course?.title || content?.course?.token
                }
              />
            )}
            {content?.prev_cpl_applicant && (
              <FieldSelector
                label="Previous CPL CUNY Institution"
                field={content?.prev_cpl_cuny_name}
              />
            )}

            {content?.cpl_credit_options !==
            'Other forms of Credit for Prior Learning' ? (
              <FieldSelector
                label="CPL Credit Options"
                field={
                  content?.cpl_credit_options?.title ||
                  content?.cpl_credit_options?.token
                }
              />
            ) : (
              <div>
                <FieldSelector
                  label="CPL Credit Options"
                  field={
                    content?.cpl_credit_options?.title ||
                    content?.cpl_credit_options?.token
                  }
                />
              </div>
            )}

            {content?.cpl_course && (
              <FieldSelector
                label="Course"
                field={
                  content?.cpl_course?.title ||
                  content?.cpl_course?.token
                }
              />
            )}
            {content?.valid_photo_id && (
              <AttachmentSelector
                type="Valid Photo ID"
                file={content?.valid_photo_id}
                filename={content?.valid_photo_id}
              />
            )}
            {content?.resume_cv && (
              <AttachmentSelector
                type="Resume/CV"
                file={content?.resume_cv}
                filename={content?.resume_cv}
              />
            )}
            {content?.documentation_one && (
              <AttachmentSelector
                type="Documentation Attachment Field 1"
                file={content?.documentation_one}
                filename={content?.documentation_one}
              />
            )}
            {content?.documentation_two && (
              <AttachmentSelector
                type="Documentation Attachment Field 2"
                file={content?.documentation_two}
                filename={content?.documentation_two}
              />
            )}
            {content?.personal_statement && (
              <AttachmentSelector
                type="Personal Statement"
                file={content?.personal_statement}
                filename={content?.personal_statement}
              />
            )}
            {content?.personal_statement_support && (
              <AttachmentSelector
                type="Supporting Document for Personal Statement"
                file={content?.personal_statement_support}
                filename={content?.personal_statement_support}
              />
            )}
            </span>
          <br />
          <br />
          <p>Created {moment(content?.created).format('ll')}</p>
          <p>Last modified {moment(content?.modified).fromNow('ll')} ago</p>
        </Form>
      )}
    </Container>
  );
};

export default CPLDocList;
