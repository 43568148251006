/**
 * Register container.
 * @module components/theme/Register/Register
 */

import React, { Component } from 'react';
import { Helmet } from '@plone/volto/helpers';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { defineMessages, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Form, Toast } from '@plone/volto/components';
import { createUser } from '@plone/volto/actions';

const messages = defineMessages({
  title: {
    id: 'Registration form',
    defaultMessage: 'Registration form',
  },
  default: {
    id: 'Default',
    defaultMessage: 'Default',
  },
  fullnameTitle: {
    id: 'Full Name',
    defaultMessage: 'Full Name',
  },
  fullnameDescription: {
    id: 'Enter full name, e.g. John Smith.',
    defaultMessage: 'Enter full name, e.g. John Smith.',
  },
  emailTitle: {
    id: 'Personal E-Mail',
    defaultMessage: 'Personal E-Mail',
  },
  emailDescription: {
    id:
      'Enter an email address. This will be your login name. We respect your privacy, and will not give the address away to any third parties or expose it anywhere.',
    defaultMessage:
      'Enter an email address. This will be your login name. We respect your privacy, and will not give the address away to any third parties or expose it anywhere.',
  },
  employeeID: {
    id: 'emplID',
    defaultMessage: 'emplID',
  },
  first_name: {
    id: 'First Name',
    defaultMessage: 'First Name',
  },
  last_name: {
    id: 'Last Name',
    defaultMessage: 'Last Name',
  },
  successRegisterCompletedTitle: {
    id: 'Account Registration Completed',
    defaultMessage: 'Account Registration Completed',
  },
  successRegisterCompletedBody: {
    id:
      'The registration process has been successful. Please check your e-mail inbox for information on how activate your account.',
    defaultMessage:
      'The registration process has been successful. Please check your e-mail inbox for information on how activate your account.',
  },
  register: {
    id: 'Register',
    defaultMessage: 'Register',
  },
});

/**
 * Register class.
 * @class Register
 * @extends Component
 */
class Register extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    createUser: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    loaded: PropTypes.bool.isRequired,
    error: PropTypes.shape({ message: PropTypes.string }),
  };

  /**
   * Default properties.
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    error: null,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs WysiwygEditor
   */
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      error: null,
    };
  }

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.loading && nextProps.loaded) {
      toast.success(
        <Toast
          success
          title={this.props.intl.formatMessage(
            messages.successRegisterCompletedTitle,
          )}
          content={this.props.intl.formatMessage(
            messages.successRegisterCompletedBody,
          )}
        />,
        { autoClose: 180000 },
      );
      this.props.history.push('/login');
    }
    // if (nextProps.error) {
    //   toast.error(nextProps.error.message.data);
    // }

    if (this.props.loading && nextProps.error) {
      const message =
        nextProps.error.response?.body?.message ||
        nextProps.error.response?.text;

      const error =
        new DOMParser().parseFromString(message, 'text/html')?.all[0]
          ?.textContent || message;

      this.setState({ error: error });

      toast.error(
        <Toast
          error
          title={`Invalid Email`}
          content={<p>The email address (user name) you enter is already in use or is not valid. Please try to <Link
            to='/login'>log in</Link> with that email or <Link to='/passwordreset'>reset your password</Link> or choose
            another email.</p>}
        />,
        { autoClose: 60000 },
      );
    }
  }

  /**
   * On submit handler
   * @method onSubmit
   * @param {object} data Form data.
   * @returns {undefined}
   */
  onSubmit(data) {
    const email = data.email;
    if (email) {
      if (
        email.endsWith('@yorkmail.cuny.edu') ||
        email.endsWith('@york.cuny.edu') ||
        email.endsWith('@login.cuny.edu')
      ) {
        this.setState({
          error:
            'Please login with your CUNY login credentials (preferred) or your York College network account.',
        });

        toast.error(
          <Toast
            error
            title={`Invalid Email`}
            content={
              <p>
                The email address you entered is associated with a <strong>CUNY login</strong> or{' '}
                <strong>York Network Account</strong>. Please try to{' '}
                <Link to='/login'>log in</Link> with that account or choose a
                personal email.
              </p>
            }
          />,
          { autoClose: 60000 },
        );
      } else {
        this.props.createUser({
          fullname: data.fullname,
          email: data.email,
          first_name: data.first_name,
          last_name: data.last_name,
          employeeID: data.employeeID,
          password: data.password,
        });
        this.setState({
          error: null,
        });
      }
    } else {
      this.setState({
        error: 'Email is required.',
      });
    }
  }
  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    return (
      <div id="page-register">
        <Helmet title={this.props.intl.formatMessage(messages.register)} />
        <Form
          onSubmit={this.onSubmit}
          title={this.props.intl.formatMessage(messages.title)}
          error={this.state.error}
          loading={this.props.loading}
          submitLabel={this.props.intl.formatMessage(messages.register)}
          schema={{
            fieldsets: [
              {
                id: 'default',
                title: this.props.intl.formatMessage(messages.default),
                fields: [
                  'fullname',
                  'email',
                  'first_name',
                  'last_name',
                  'employeeID',
                ],
              },
            ],
            properties: {
              fullname: {
                type: 'string',
                title: this.props.intl.formatMessage(messages.fullnameTitle),
                description: this.props.intl.formatMessage(
                  messages.fullnameDescription,
                ),
              },
              email: {
                type: 'email',
                title: this.props.intl.formatMessage(messages.emailTitle),
                description: this.props.intl.formatMessage(
                  messages.emailDescription,
                ),
              },
              first_name: {
                type: 'string',
                title: this.props.intl.formatMessage(messages.first_name),
              },
              last_name: {
                type: 'string',
                title: this.props.intl.formatMessage(messages.last_name),
              },
              employeeID: {
                type: 'string',
                title: this.props.intl.formatMessage(messages.employeeID),
              },
            },
            required: ['fullname', 'email', 'first_name', 'last_name'],
          }}
        />
      </div>
    );
  }
}

export default compose(
  withRouter,
  injectIntl,
  connect(
    (state) => ({
      loading: state.users.create.loading,
      loaded: state.users.create.loaded,
      error: state.users.create.error,
    }),
    { createUser },
  ),
)(Register);
