import React from 'react';
import { TextArea, Button, Form, Modal } from 'semantic-ui-react';
import Workflow from 'volto-yc-documents/components/ContentStatusHistory/Workflow';
import { transitionWorkflow, getWorkflow } from '@plone/volto/actions/workflow/workflow';
import { raw } from 'volto-yc-documents/actions';
import { useDispatch } from 'react-redux';
import { updateContent, getContent } from '@plone/volto/actions/content/content';
import { flattenToAppURL } from '@plone/volto/helpers/Url/Url';

const ContentStatusHistory = (item) => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [comment, setComment] = React.useState('');
  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const transitionValue = document.getElementById('transitionValue').innerHTML;
    await dispatch(raw(transitionValue, comment, true));
    setOpen(false);
    await dispatch(
      updateContent(item?.['@id'], { review_state: item?.review_state }),
    );
    return false;
  };
  return (
    <Modal
      className="view-wrapper"
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={
        <Button
          type="submit"
          className="ui primary basic button"
          title="Change State"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            return false;
          }}
        >
          Transition
        </Button>
      }
    >
      <Modal.Header>
        <div className="ui header">Change State</div>
      </Modal.Header>
      <Modal.Content>
        <p className="ui description">Current State: {item?.review_state}</p>
        <p>Select a state below to modify</p>
        <Form onSubmit={handleSubmit}>
          <div className="Transition">
            <Workflow pathname={flattenToAppURL(item['@id'])} getWorkflow={getWorkflow} getContent={getContent}
                      transitionWorkflow={transitionWorkflow} />
          </div>
          <br />
          <div className="Comments">
            <p>Comments below are added to the history and sent by email to the creator if enabled for this
              transition.</p>
            <TextArea
              id="comment"
              type="text"
              name="comment"
              cols="60"
              rows="5"
              value={comment}
              onChange={(event) => {
                event.preventDefault();
                event.stopPropagation();
                setComment(event.target.value);
              }}
              style={{ border: 'solid #eee 1px', marginBottom: '1em' }}
            />
          </div>
          <Modal.Actions>
            <Button className="ui basic circular primary right floated button" aria-label="Save" title="Save"
                    type="submit">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36"
                   style={{ height: '30px', width: 'auto', fill: 'currentcolor' }} className="icon contents circled">
                <path fillRule="evenodd"
                      d="M18.707 5.293L17.293 6.707 27.586 17 5 17 5 19 27.586 19 17.293 29.293 18.707 30.707 31.414 18z"></path>
              </svg>
            </Button>
            <Button onClick={() => setOpen(false)} className="ui basic circular secondary right floated button"
                    aria-label="Cancel" title="Cancel" type="reset">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36"
                   style={{ height: '30px', width: 'auto', fill: 'currentcolor' }} className="icon circled">
                <path fillRule="evenodd"
                      d="M27.899 9.515L26.485 8.101 18 16.586 9.514 8.101 8.1 9.515 16.586 18 8.1 26.486 9.514 27.9 18 19.414 26.485 27.9 27.899 26.486 19.414 18z"></path>
              </svg>
            </Button>
          </Modal.Actions>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default ContentStatusHistory;
