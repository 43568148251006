import React from 'react';
import { Container, Form, Grid, Message, Segment } from 'semantic-ui-react';
import DocsPortalImage from 'volto-yc-documents/components/documents-portal-unauthenticated.svg';
import { Link, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const Unauthorized = () => {
  let location = useLocation();
  return (
    <Container>
      <Form>
        <title>Protected Resource</title>
        <Grid stackable verticalAlign='middle' centered>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Grid>
                <Grid.Row columns={1}>
                  <Grid.Column>
                    <h1>Documents Portal</h1>
                    <p>Please login with your <strong>CUNY login credentials
                      (preferred)</strong>, your <a href='https://www.york.cuny.edu/myid/'>
                      York College network account
                    </a>, or your registered personal email.
                    </p><br />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                  <Grid.Column>
                    <a
                      className='ui button tertiary fluid'
                      href='/acl_users/saml/sls'
                    >
                      CUNY Login
                    </a><br />
                  </Grid.Column>
                  <Grid.Column>
                    <Link
                      className='ui button primary fluid'
                      to={`/login?return_url=${location.pathname}`}
                    >
                      York Network Account Login
                    </Link><br />
                  </Grid.Column>
                  <Grid.Column>
                    <Link
                      className='ui button secondary fluid'
                      to={`/login?return_url=${location.pathname}`}
                    >
                      Registered Personal Email Login
                    </Link><br />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <Segment.Group raised>
                <Message info>
                  <p>
                    <strong>Forgot your password?</strong>< br />You can reset your password online for: <a
                    href='https://managelogin.cuny.edu/OIMSelfService/forgotpwd?execution=e2s1'>
                    CUNY Login
                  </a> | <a href='https://www.york.cuny.edu/stpassword/'>
                    Student Network Account
                  </a> | <a href='https://www.york.cuny.edu/password/'>
                    Faculty/Staff Network Account
                  </a> | <Link to='/passwordreset'>Registered Personal Email</Link>.
                  </p>
                  <p>
                    <strong>Need an account?</strong>< br />If you have never
                    registered in this system before and you do not have an active CUNY Login or York Network account,
                    you can <Link
                    to='/register'>register with your
                    personal email address</Link> to upload documents.
                  </p>
                </Message>{' '}
              </Segment.Group>
              <br />
              <Grid>
                <Grid.Row columns={1}>
                  <Grid.Column>
                    <br />
                    <p><strong> Note: </strong> All files uploaded must only be in
                      one of the following file types: <strong>JPEG, PNG, or PDF</strong>.</p>
                    <FormattedMessage
                      id='If you are certain you have the correct web address but are encountering an error, please contact the {site_admin}.'
                      defaultMessage='If you are certain you have the correct web address but are encountering an error, please contact the {site_admin}.'
                      values={{
                        site_admin: (
                          <Link to='/contact-form'>
                            <FormattedMessage
                              id='Site Administration'
                              defaultMessage='Site Administration'
                            />
                          </Link>
                        ),
                      }}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>{' '}
            <Grid.Column textAlign='center'>
              <img
                src={DocsPortalImage}
                alt={DocsPortalImage}
                style={{ width: '50%' }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </Container>
  );
};
export default Unauthorized;
