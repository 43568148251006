import moment from 'moment';
import { Container, Form } from 'semantic-ui-react';
import React, { useEffect, useState } from 'react';
import PDFViews from 'volto-yc-documents/components/PDFViews/PDFViews';
import YCImageView from 'volto-yc-documents/components/YCImageView/YCImageView';
import Helmet from '@plone/volto/helpers/Helmet/Helmet';
import { useDispatch, useSelector } from 'react-redux';
import DefaultLoader from 'volto-yc-documents/components/ContentTypesViews/HelperComponents/DefaultLoader';
import { getUser } from '@plone/volto/actions/users/users';
import jwtDecode from 'jwt-decode';

const DocListView = (props) => {
  const { content, token } = props;
  const id = jwtDecode(token).sub;
  const dispatch = useDispatch();
  const email = (id) => {
    let response = dispatch(getUser(id));
    let email = response?.email;
    return email;
  };
  const AttachmentSelector = ({ type, file }) => {
    return (
      <div>
        {content?.preview === 'True' ? (
          <div>
            <p>
              <strong> Attachment: </strong>
              {(type && type?.title) ||
                (type && type) ||
                (type && type?.toString()) ||
                (type && type?.token)}{' '}
              - <a href={file?.download}>{file?.filename}</a>
            </p>
            {file ? (
              <PDFViews
                file={file?.download}
                type={file?.['content-type']}
                filename={file?.filename}
              />
            ) : (
              <YCImageView file={file?.scales?.preview?.download} />
            )}{' '}
          </div>
        ) : (
          <p>
            <strong> Attachment: </strong>
            {(type && type?.title) ||
              (type && type) ||
              (type && type?.toString()) ||
              (type && type?.token)}{' '}
            - {file?.filename}
          </p>
        )}
      </div>
    );
  };

  const FieldSelector = ({ label, field }) => {
    if (label && field) {
      return (
        <span>
          <strong>{field && label}: </strong>
          {field && field}
          <br />
        </span>
      );
    }
    return <span>{field && field}</span>;
  };
  const description =
    content?.last_name +
    ', ' +
    content?.first_name +
    ' - ' +
    content?.employeeID;
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    let timer = setTimeout(() => setLoaded(true), 200);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  return (
    <Container>
      {!loaded ? (
        <DefaultLoader active={!loaded} />
      ) : (
        <Form>
          <Helmet title={description} />
          <h1 className="documentFirstHeading">{description}</h1>
          {id && (
            <FieldSelector label="Email" field={email(id)} />
          )}
          {content?.term0?.title && (
            <FieldSelector label="Term" field={content?.term0?.title} />
          )}
          {content?.term && (
            <FieldSelector label="Year" field={content?.term} />
          )}
          {content?.course_number && (
            <FieldSelector
              label="Course Number"
              field={content?.course_number}
            />
          )}
          {content?.course_section && (
            <FieldSelector
              label="Course Section"
              field={content?.course_section}
            />
          )}
          {content?.five_digit_code && (
            <FieldSelector
              label="Five Digit Code"
              field={content?.five_digit_code}
            />
          )}
          {content?.current_grade?.token && (
            <FieldSelector
              label="Current Grade"
              field={content?.current_grade?.token}
            />
          )}
          {content?.final_grade?.token && (
            <FieldSelector
              label="Final Grade"
              field={content?.final_grade?.token}
            />
          )}
          {content?.grade_change_rational && (
            <FieldSelector
              label="Grade Change Rational"
              field={content?.grade_change_rational}
            />
          )}
          {content?.acknowledgement?.title && (
            <FieldSelector
              label="Acknowledgement"
              field={content?.acknowledgement?.title}
            />
          )}
          {content?.instructure_s_name && (
            <FieldSelector
              label="Instructor's Name"
              field={content?.instructure_s_name}
            />
          )}
          {content?.instructor_signature && (
            <FieldSelector
              label="Instructor's Signature"
              field={content?.instructor_signature}
            />
          )}
          {content?.chairperson_s_name && (
            <FieldSelector
              label="Chairperson's Name"
              field={content?.chairperson_s_name}
            />
          )}
          {content?.chairperson_s_signature && (
            <FieldSelector
              label="Chairperson's Signature"
              field={content?.chairperson_s_signature}
            />
          )}
          {content?.provost_s_signature && (
            <FieldSelector
              label="Provost's Signature"
              field={content?.provost_s_signature}
            />
          )}
          {content?.academic_year?.title && (
            <FieldSelector
              label="Semester"
              field={content?.academic_year?.title}
            />
          )}

          {content?.attachment_file && (
            <AttachmentSelector
              type={content?.attachment_type}
              file={content?.attachment_file}
              filename={content?.attachment_file}
            />
          )}

          {content?.attachment_file1 && (
            <AttachmentSelector
              type={content?.attachment_type1}
              file={content?.attachment_file1}
              filename={content?.attachment_file1}
            />
          )}

          {content?.attachment_file2 && (
            <AttachmentSelector
              type={content?.attachment_type2}
              file={content?.attachment_file2}
              filename={content?.attachment_file2}
            />
          )}

          {content?.attachment_file3 && (
            <AttachmentSelector
              type={
                content?.attachment_type3?.title ||
                content?.attachment_type3?.token
              }
              file={content?.attachment_file3}
              filename={content?.attachment_file3}
            />
          )}

          {content?.attachment_file4 && (
            <AttachmentSelector
              type={
                content?.attachment_type4?.title ||
                content?.attachment_type4?.token
              }
              file={content?.attachment_file4}
              filename={content?.attachment_file4}
            />
          )}
          {content?.attachment_file5 && (
            <AttachmentSelector
              type={
                content?.attachment_type5?.title ||
                content?.attachment_type5?.token
              }
              file={content?.attachment_file5}
              filename={content?.attachment_file5}
            />
          )}
          {content?.attachment_file6 && (
            <AttachmentSelector
              type={
                content?.attachment_type6?.title ||
                content?.attachment_type6?.token
              }
              file={content?.attachment_file6}
              filename={content?.attachment_file6}
            />
          )}
          {content?.transcript_file && (
            <AttachmentSelector
              type="Transcript"
              file={content?.transcript_file}
              filename={content?.transcript_file}
            />
          )}
          {content?.degreeworks_file && (
            <AttachmentSelector
              type="DegreeWorks"
              file={content?.degreeworks_file}
              filename={content?.degreeworks_file}
            />
          )}
          {content?.other_file && (
            <AttachmentSelector
              type="other"
              file={content?.other_file}
              filename={content?.other_file}
            />
          )}
          {content?.previous_petition_file && (
            <AttachmentSelector
              type="Previous Petition"
              file={content?.previous_petition_file}
              filename={content?.previous_petition_file}
            />
          )}
          <br />
          <br />
          <p>Created {moment(content?.created).format('ll')}</p>
          <p>Last modified {moment(content?.modified).fromNow('ll')} ago</p>
        </Form>
      )}
    </Container>
  );
};

export default DocListView;
