export const links = [
  {
    name: 'Admissions',
    link: 'https://www.york.cuny.edu/admissions',
    styles: 'cd-nav-icons',
    sublinks: [
      {
        name: 'Freshman Admissions',
        link: 'https://www.york.cuny.edu/admissions/freshman',
        styles: 'item-1',
        description: 'I have never attended college',
      },
      {
        name: 'Transfer Admissions',
        link: 'https://www.york.cuny.edu/admissions/transfer',
        styles: 'item-2',
        description: 'I studied at other post-secondary institutions',
      },
      {
        name: 'Graduate Admissions',
        link: 'https://www.york.cuny.edu/admissions/graduate',
        styles: 'item-3',
        description: 'I graduated from a post-secondary institutions',
      },
      {
        name: 'International Admissions',
        link: 'https://www.york.cuny.edu/admissions/international',
        styles: 'item-4',
        description: 'I\'m not a US Resident / Citizen',
      },
      {
        name: 'Veterans Admission',
        link: 'https://www.york.cuny.edu/admissions/veterans',
        styles: 'item-5',
        description: 'I served in the US military',
      },
      {
        name: 'Other Applicants',
        link: 'https://www.york.cuny.edu/admissions/others',
        styles: 'item-6',
        description: 'Non-degree, Second Degree, Reactivation',
      },
      {
        name: 'Admissions Team/Office Hours',
        link: 'https://www.york.cuny.edu/admissions/team',
        styles: 'item-7',
        description: 'Meet the York College Admission Team, Virtual and In-person Walk-In Hours',
      },
      {
        name: 'Campus Tour',
        link: 'https://www.york.cuny.edu/admissions/campus-tours',
        styles: 'item-8',
        description: 'Take a virtual Tour of our campus',
      },
      {
        name: 'Financial Aid',
        link: 'https://www.york.cuny.edu/financial-aid',
        styles: 'item-9',
        description: 'I need help paying for college',
      },
    ],
  },
];
