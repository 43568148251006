import React, { useEffect, useState } from 'react';
import { Container, Dropdown, Form, Grid, Message, Segment } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import DocsPortalImage from 'volto-yc-documents/components/documents-portal-login.svg';
import { Link } from 'react-router-dom';
import MyDocuments from 'volto-yc-documents/components/MyDocuments';
import { flattenToAppURL } from '@plone/volto/helpers/Url/Url';
import DefaultLoader from 'volto-yc-documents/components/ContentTypesViews/HelperComponents/DefaultLoader';

const Home = (props) => {
  const { content } = props;
  const options = [
    {
      key: 'Admissions - Graduate Program Application - Aviation (MS)',
      text: 'Admissions - Graduate Program Application - Aviation (MS)',
      value: 'grad/aviation/add?type=applicationform',
    },
    {
      key:
        'Admissions - Graduate Program Application - Clinical Trial Management (MS)',
      text:
        'Admissions - Graduate Program Application - Clinical Trial Management (MS)',
      value: 'grad/ctm/add?type=applicationform',
    },
    {
      key:
        'Admissions - Graduate Program Application - Pharmaceutical Science and Business (MS)',
      text:
        'Admissions - Graduate Program Application - Pharmaceutical Science and Business (MS)',
      value: 'grad/psb/add?type=applicationform',
    },
    {
      key: 'Admissions Office',
      text: 'Admissions Office',
      value: 'admissions/add?type=admissions_docs',
    },
    {
      key: 'Athletics',
      text: 'Athletics',
      value: 'athletics/add?type=athletics',
    },
    {
      key: 'Bursar Office',
      text: 'Bursar Office',
      value: 'bursar/add?type=bursar_docs',
    },
    {
      key: 'Center for Students with Disabilities',
      text: 'Center for Students with Disabilities',
      value: 'csd/add?type=csd',
    },
    {
      key: 'Childcare Center',
      text: 'Childcare Center',
      value: 'childcare-center/add?type=childcare',
    },
    {
      key: 'Credit for Prior Learning',
      text: 'Credit for Prior Learning',
      value: 'cpl/add?type=cpl',
    },
    {
      key: 'Financial Aid Office',
      text: 'Financial Aid Office',
      value: 'financial-aid/add?type=finaid_docs',
    },
    {
      key: 'Health Services Center',
      text: 'Health Services Center',
      value: 'health/add?type=health_docs',
    },
    {
      key: 'Information Technology',
      text: 'Information Technology',
      value: 'it/add?type=it',
    },
    {
      key: 'Learning Center',
      text: 'Learning Center',
      value: 'learning/add?type=learning_docs',
    },
    {
      key: 'Occupational Therapy Department',
      text: 'Occupational Therapy Department',
      value: 'ot/add?type=ot',
    },
    {
      key: 'Office of Student Academic Services (OSAS)',
      text: 'Office of Student Academic Services (OSAS)',
      value: 'osas/add?type=osas_docs',
    },
    {
      key: 'Office of Veterans Affairs',
      text: 'Office of Veterans Affairs',
      value: 'va/add?type=va',
    },
    {
      key: 'Public Safety ID',
      text: 'Public Safety ID',
      value: 'psid/add?type=psid',
    },
    {
      key: 'Registrar Office',
      text: 'Registrar Office',
      value: 'registrar/add?type=registrar_docs',
    },
    {
      key: 'Relief Fund',
      text: 'Relief Fund',
      value: 'relief-fund/add?type=relief_fund_docs',
    },
    {
      key: 'Student Activity Office',
      text: 'Student Activity Office',
      value: 'student-activities/add?type=student_activity',
    },
    {
      key: 'York Early College Academy (YECA)',
      text: 'York Early College Academy (YECA)',
      value: 'yeca/add?type=yeca',
    },
  ];
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    let timer = setTimeout(() => setLoaded(true), 2000);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  return (
    <Container>
      {!loaded ? (
        <DefaultLoader active={!loaded} />
      ) : (
        <Form>
          <title>{content.title}</title>
          <header>
            <h1>
              <Link to={flattenToAppURL('/')}>Documents Portal</Link>
            </h1>
          </header>
          <Segment.Group raised>
            <Message>
              <span>
                {' '}
                <strong>Submit new documents to: </strong>
                <Dropdown
                  icon={
                    <div
                      aria-label='Next page'
                      data-testid='page-navigation__next0button'
                    >
                      <svg
                        aria-hidden='true'
                        className='rpv-core__icon'
                        focusable='false'
                        height='16px'
                        viewBox='0 0 24 24'
                        width='16px'
                      >
                        <path
                          d='M0.541,5.627L11.666,18.2c0.183,0.207,0.499,0.226,0.706,0.043c0.015-0.014,0.03-0.028,0.043-0.043
            L23.541,5.627'
                        ></path>
                      </svg>
                    </div>
                  }
                  placeholder='Please Select Office'
                  selection
                  scrolling
                >
                  <Dropdown.Menu>
                    {options.map((option) => (
                      <Dropdown.Item key={option.key}>
                        <div>
                          <Link to={flattenToAppURL(option.value)}>
                            <p>{option.text}</p>
                          </Link>
                        </div>
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </span>
            </Message>
          </Segment.Group>
          <div>
            <strong> Note: </strong> All files uploaded must only be in one of
            the following file types: JPEG, PNG, or PDF.
          </div>
          <br />
          <MyDocuments />
        </Form>
      )}
    </Container>
  );
};

const Anonymous = (props) => {
  const { content } = props;
  return (
    <Container>
      <Form>
        <title>{content.title}</title>
        <Grid stackable verticalAlign='middle' centered>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Grid>
                <Grid.Row columns={1}>
                  <Grid.Column>
                    <h1>Documents Portal</h1>
                    <p>Please login with your <strong>CUNY login credentials
                      (preferred)</strong>, your <a href='https://www.york.cuny.edu/myid/'>
                      York College network account
                    </a>, or your registered personal email.
                    </p><br />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                  <Grid.Column>
                    <a
                      className='ui button tertiary fluid'
                      href='/acl_users/saml/sls'
                    >
                      CUNY Login
                    </a><br />
                  </Grid.Column>
                  <Grid.Column>
                    <Link
                      className='ui button primary fluid'
                      to='/login?return_url='
                    >
                      York Network Account Login
                    </Link><br />
                  </Grid.Column>
                  <Grid.Column>
                    <Link
                      className='ui button secondary fluid'
                      to='/login?return_url='
                    >
                      Registered Personal Email Login
                    </Link><br />
                  </Grid.Column>
                </Grid.Row>

              </Grid>
              <Segment.Group raised>
                <Message info>
                  <p>
                    <strong>Forgot your password?</strong>< br />You can reset your password online for: <a
                    href='https://managelogin.cuny.edu/OIMSelfService/forgotpwd?execution=e2s1'>
                    CUNY Login
                  </a> | <a href='https://www.york.cuny.edu/stpassword/'>
                    Student Network Account
                  </a> | <a href='https://www.york.cuny.edu/password/'>
                    Faculty/Staff Network Account
                  </a> | <Link to='/passwordreset'>Registered Personal Email</Link>.
                  </p>
                  <p>
                    <strong>Need an account?</strong>< br />If you have never
                    registered in this system before and you do not have an active CUNY Login or York Network account,
                    you
                    can <Link
                    to='/register'>register with your
                    personal email address</Link> to upload documents.
                  </p>
                </Message>{' '}
              </Segment.Group>
              <br />
              <Grid>
                <Grid.Row columns={1}>
                  <Grid.Column>
                    <br />
                    <strong> Note: </strong> All files uploaded must only be in
                    one of the following file types: JPEG, PNG, or PDF.
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>{' '}
            <Grid.Column textAlign='center'>
              <img
                src={DocsPortalImage}
                alt={DocsPortalImage}
                style={{ width: '50%' }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </Container>
  );
};

const HomePage = (props) => {
  const userid = useSelector((state) =>
    state.userSession.token ? jwtDecode(state.userSession.token).sub : '',
  );
  return userid ? <Home {...props} /> : <Anonymous {...props} />;
};

export default HomePage;
