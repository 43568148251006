/**
 * Navigation components.
 * @module components/theme/Navigation/Navigation
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { getBaseUrl, hasApiExpander } from '@plone/volto/helpers';
import config from '@plone/volto/registry';
import { getNavigation } from '@plone/volto/actions';
import { CSSTransition } from 'react-transition-group';
import { Link } from 'react-router-dom';
import { Logo } from '@plone/volto/components';
import { links } from './megamenu';

class Navigation extends Component {

  static propTypes = {
    getNavigation: PropTypes.func.isRequired,
    pathname: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string, url: PropTypes.string,
    }),).isRequired,
    lang: PropTypes.string.isRequired,
  };

  static defaultProps = {
    token: null,
  };

  componentDidMount() {
    const { settings } = config;
    if (!hasApiExpander('navigation', getBaseUrl(this.props.pathname))) {
      this.props.getNavigation(getBaseUrl(this.props.pathname), settings.navDepth);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { settings } = config;
    if (nextProps.pathname !== this.props.pathname || nextProps.token !== this.props.token) {
      if (!hasApiExpander('navigation', getBaseUrl(this.props.pathname))) {
        this.props.getNavigation(getBaseUrl(nextProps.pathname), settings.navDepth);
      }
    }
  }

  state = {
    mobileMenu: false,
    primaryNav: '',
    secondaryNav: '',
    tertiaryNav: '',
    sectionNav: '',
  };
  closeMobileMenu = () => {
    this.setState({
      primaryNav: '',
      secondaryNav: '',
      tertiaryNav: '',
      sectionNav: '',
      mobileMenu: false,
    });
  };

  render() {
    return (<>
        <header className={`${this.state.mobileMenu === true ? 'cd-main-header nav-is-visible' : 'cd-main-header'}`}>
          <a className='cd-logo' href='https://www.york.cuny.edu/' onClick={this.closeMobileMenu}><Logo /></a>
          <ul className='cd-header-buttons'>
            <li><a
              className={`${this.state.primaryNav === 'search' ? 'cd-search-trigger search-is-visible' : 'cd-search-trigger'}`}
              onClick={() => {
                this.setState(prevState => ({
                  primaryNav:
                    prevState.primaryNav !== 'search' ? 'search' : '',
                  secondaryNav: '',
                  sectionNav: '',
                  mobileMenu: false,
                }));
              }} href='javascript:'><span></span><span className='is-hidden'>Search</span></a></li>
            <li><a
              className={`${this.state.mobileMenu === true ? 'cd-nav-trigger nav-is-visible' : 'cd-nav-trigger'}`}
              onClick={() => {
                this.setState(prevState => ({
                  mobileMenu: !prevState.mobileMenu,
                  primaryNav: '',
                  secondaryNav: '',
                  sectionNav: '',
                }));
              }} href='javascript:'><span>Menu</span></a></li>
          </ul>
          <nav className={`${this.state.mobileMenu === true ? 'cd-nav cd-nav-is-visible' : 'cd-nav'}`}
               id='navigation' aria-label='navigation'>
            <ul id='cd-primary-nav'
                className={`${this.state.mobileMenu === true ? 'cd-primary-nav nav-is-visible' : 'cd-primary-nav'} ${this.state.primaryNav !== '' ? 'moves-out' : ''}`}>

              {links.map((link) => (
                <li className='has-children'>
                  <a className={`${this.state.primaryNav === link.name ? 'selected' : ''}`}
                     onClick={(event) => {
                       event.preventDefault();
                       this.state.primaryNav !== link.name ? this.setState(prevState => ({ primaryNav: link.name })) : this.setState(prevState => ({ primaryNav: '' }));
                       this.setState(prevState => ({ secondaryNav: '' }));
                       this.setState(prevState => ({ tertiaryNav: '' }));
                       this.setState(prevState => ({ sectionNav: '' }));
                     }} href={link.link}>
                    {link.name}</a>

                  <CSSTransition
                    in={this.state.primaryNav === link.name}
                    timeout={500}
                    classNames='primary'
                    unmountOnExit>
                    <ul
                      className={link.styles + ` ${this.state.primaryNav !== link.name ? 'cd-hidden' : ''}` + ` ${this.state.secondaryNav !== '' ? 'moves-out' : ''}`}>
                      <li className='go-back'><a href='#' onClick={(event) => {
                        event.preventDefault();
                        this.state.primaryNav !== link.name ? this.setState(prevState => ({ primaryNav: link.name })) : this.setState(prevState => ({ primaryNav: '' }));
                        this.setState(prevState => ({ secondaryNav: '' }));
                        this.setState(prevState => ({ sectionNav: '' }));
                      }}>Main Menu</a></li>
                      <li className='see-all'><Link to={link.link}
                                                    onClick={this.closeMobileMenu}>{link.name} Resources</Link></li>
                      {link.styles === 'cd-nav-icons' && (
                        link.sublinks.map((secondary) => (
                          <li>
                            {secondary.link.startsWith('http') ?
                              <a className={'cd-nav-item ' + secondary.styles}
                                 href={secondary.link} onClick={this.closeMobileMenu}>
                                <h3>{secondary.name}</h3>
                                <p>{secondary.description}</p>
                              </a> :
                              <Link className={'cd-nav-item ' + secondary.styles}
                                    to={secondary.link} onClick={this.closeMobileMenu}>
                                <h3>{secondary.name}</h3>
                                <p>{secondary.description}</p>
                              </Link>}
                          </li>
                        ))
                      )}

                      {link.styles === 'cd-nav-gallery' && (
                        link.sublinks.map((secondary) => (
                          <li>
                            {secondary.link.startsWith('http') ?
                              <a className={'cd-nav-item ' + secondary.styles}
                                 href={secondary.link} onClick={this.closeMobileMenu}>
                                <img src={secondary.image}
                                     className='img-circle' alt=' ' />
                                <h3>{secondary.name}</h3>
                              </a> :
                              <Link className={'cd-nav-item ' + secondary.styles}
                                    to={secondary.link} onClick={this.closeMobileMenu}>
                                <img src={secondary.image}
                                     className='img-circle' alt=' ' />
                                <h3>{secondary.name}</h3>
                              </Link>}
                          </li>
                        ))
                      )}

                      {link.styles === 'cd-secondary-nav' && (
                        link.sublinks.map((secondary) => (
                          <li className='has-children'>
                            <a href={secondary.link}
                               onClick={(event) => {
                                 event.preventDefault();
                                 this.state.secondaryNav !== secondary.name ?
                                   this.setState(prevState => ({ secondaryNav: secondary.name })) :
                                   this.setState(prevState => ({ secondaryNav: '' }));
                                 this.setState(prevState => ({ tertiaryNav: '' }));
                               }}>{secondary.name}</a>
                            <ul
                              className={`${this.state.secondaryNav !== secondary.name ? 'cd-hidden' : ''}` + ` ${this.state.tertiaryNav !== '' & this.state.secondaryNav === secondary.name ? 'moves-out' : ''}`}>
                              {this.state.mobileMenu === true && (
                                <>
                                  <li className='go-back'><a href='#'
                                                             onClick={(event) => {
                                                               event.preventDefault();
                                                               this.setState(prevState => ({ secondaryNav: '' }));
                                                               this.setState(prevState => ({ tertiaryNav: '' }));
                                                             }}>Back to {link.name}</a></li>
                                  <li className='see-all'>
                                    <Link to={secondary.link}
                                          onClick={this.closeMobileMenu}>{secondary.name}</Link>
                                  </li>
                                </>)}
                              {secondary.sublink.map((tertiaryNav) => (
                                tertiaryNav.styles === 'has-children' ?
                                  <li className='has-children'>
                                    <a href={tertiaryNav.link}
                                       onClick={(event) => {
                                         event.preventDefault();
                                         this.setState(prevState => ({ tertiaryNav: tertiaryNav.name }));
                                       }}>{tertiaryNav.name}</a>
                                    <ul
                                      className={`${this.state.tertiaryNav !== tertiaryNav.name ? 'cd-hidden' : ''}` + ' tertiaryNav'}>

                                      <li className='go-back'><a href='#'
                                                                 onClick={(event) => {
                                                                   event.preventDefault();
                                                                   this.setState(prevState => ({ tertiaryNav: '' }));
                                                                 }}>Back to {secondary.name}</a></li>
                                      <li className='see-all'>
                                        <Link to={tertiaryNav.link}
                                              onClick={this.closeMobileMenu}>{tertiaryNav.name}</Link>
                                      </li>

                                      {tertiaryNav.sublink.map((fourNav) => (
                                        <li>
                                          {fourNav.link.startsWith('http') ?
                                            <a className={fourNav.styles} href={fourNav.link}
                                               onClick={this.closeMobileMenu}>{fourNav.name}</a> :
                                            <Link className={fourNav.styles} to={fourNav.link}
                                                  onClick={this.closeMobileMenu}>{fourNav.name}</Link>}
                                        </li>))}
                                    </ul>
                                  </li> :
                                  <li>
                                    {tertiaryNav.link.startsWith('http') ?
                                      <a className={tertiaryNav.styles} href={tertiaryNav.link}
                                         onClick={this.closeMobileMenu}>{tertiaryNav.name}</a> :
                                      <Link className={tertiaryNav.styles} to={tertiaryNav.link}
                                            onClick={this.closeMobileMenu}>{tertiaryNav.name}</Link>}
                                  </li>
                              ))}
                            </ul>
                          </li>
                        ))
                      )}

                    </ul>
                  </CSSTransition>
                </li>))}



            </ul>
          </nav>
        </header>
        <CSSTransition
          in={this.state.mobileMenu === true | this.state.primaryNav !== ''}
          timeout={500}
          classNames='in'
          unmountOnExit>
          <div className='cd-overlay is-visible'
               onClick={this.closeMobileMenu} />
        </CSSTransition>


        <CSSTransition
          in={this.state.primaryNav === 'search'}
          timeout={500}
          classNames='cd-search'
          unmountOnExit>
          <div id='cd-search' className='cd-search is-visible' role='search'>
            <form action='https://www.google.com/cse' id='cse-search-box'>
              <input name='cx' type='hidden' value='014698858644969705766:fdu3aeb0rno' />
              <input name='ie' type='hidden' value='UTF-8' />
              <input name='q' size='30' type='search' placeholder='Search...' aria-label='Search text' />
              <input name='sa' type='submit' value='Search' className='gs' aria-label='Search' />
            </form>
          </div>
        </CSSTransition>

      </>

    );
  }
}

export default compose(injectIntl, connect((state) => ({
  token: state.userSession.token, items: state.navigation.items, lang: state.intl.locale,
}), { getNavigation }),)(Navigation);
